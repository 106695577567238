<template>
    <div v-if="unitDetails">
        <ellipsis-dropdown
            componentName="edit-property"
            :showDeleteBtn="curUnitId !== undefined"
            @save="onEdit"
            @delete="onDelete"
            :itemId="curUnitId"
        />
        <div class="d-inline-flex justify-content-start align-items-center mb-1 ml-50">
            <span @click="$router.push({name:'properties'})" class="cursor-pointer d-flex align-items-center">
                <RtIcon icon="chevron-left" variant="light" :size="20" />
            </span>
            <h3 class="ml-1 mb-0">{{ unitDetails.PropertyTitle }}</h3>
        </div>
        <b-row>
            <b-col
                cols="12"
                md="6"
            >
                <b-card
                    class="section-container"
                    no-body
                >
                    <b-card-header>
                        <h4 class="card-title">{{ i18nT(`Informations`) }}</h4>
                    </b-card-header>

                    <b-card-body>
<!--                        MAP/CAROUSEL-->
                        <b-row>
                            <b-col cols="12">
                                <map-carousel :images="unitImages" :address="unitAddress" @modal-photos="openModal" :key="mapCarouselKey" btnId="normal"/>
                            </b-col>
                        </b-row>
<!--                        GENERAL INFO CARD-->
                        <b-row class="mt-2">
                            <b-col cols="12">
                                <b-card no-body border-variant="secondary" class="ren-property-info-card">
                                    <b-card-body class="p-1">
                                        <h2 class="mb-1">{{ unitDetails.PropertyTitle }}</h2>
                                        <h4>{{unitType}}</h4>
                                        <p><RtIcon icon="location-dot" variant="light mr-25" :size="16"/>{{unitAddress}}</p>
                                        <div class="ren-property-rooms">
                                            <span v-if="unitDetails.PropertySize">
                                                <RtIcon icon="home" variant="light" :size="25"/>
                                                <span class="ml-50 font-medium-1">{{ unitDetails.PropertySize }} m²</span>
                                            </span>
                                            <span v-if="unitDetails.PropertyRoomsNum">
                                                <RtIcon icon="door-open" variant="light" :size="27"/>
                                                <span class="ml-50 font-medium-1">{{ unitDetails.PropertyRoomsNum }}</span>
                                            </span>
                                            <span v-if="unitDetails.PropertyRoomsNumChambres">
                                                <RtIcon icon="bed-front" variant="light" :size="27"/>
                                                <span class="ml-50 font-medium-1">{{ unitDetails.PropertyRoomsNumChambres }}</span>
                                            </span>
                                            <span v-if="unitDetails.PropertyRoomsNumBaths">
                                                <RtIcon icon="shower" variant="light" :size="27"/>
                                                <span class="ml-50 font-medium-1">{{ unitDetails.PropertyRoomsNumBaths }}</span>
                                            </span>
                                        </div>
                                    </b-card-body>

                                </b-card>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <b-card no-body border-variant="success">
                                    <b-card-body class="p-1">
                                        <div class="d-flex">
                                            <div class="d-flex justify-content-center align-items-center mr-2">
                                                <b-avatar size="6em" variant="light-warning">
                                                    <RtIcon variant="thin" icon="coins" :size="37"/>
                                                </b-avatar>
                                            </div>
                                            <div class="flex-column"><h3>{{ i18nT(`Loyer CC`) }}</h3>
                                                <h2 class="text-success">
                                                    {{{amount: unitDetails.PropertyRent, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                                </h2>
                                                <div class="d-flex">
                                                    <p class="m-0 mr-1"><b>{{ i18nT(`Loyer CC`) }}</b>
                                                        {{{amount: unitDetails.PropertyRent, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                                    </p>
                                                    <p class="m-0"><b>{{ i18nT(`Charges`) }}</b>
                                                        {{{amount: unitDetails.PropertyMaintenance, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" lg="6" md="12">
                                <b-card no-body border-variant="success">
                                    <b-card-body class="p-1">
                                        <div class="d-flex">
                                            <div class="flex-column"><h5>{{ i18nT(`Prix d’acquisition`) }}</h5>
                                                <div class="d-flex">
                                                    <h2 class="m-0 mr-1 text-success">
                                                        {{{amount: unitDetails.PropertyInitialPrice, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                            <b-col cols="12" lg="6" md="12">
                                <b-card no-body border-variant="danger">
                                    <b-card-body class="p-1">
                                        <div class="d-flex">
                                            <div class="flex-column"><h5>{{ i18nT(`Prêt`) }} {{i18nT(`Restant dû`)}}</h5>
                                                <div class="d-flex">
                                                    <h2 class="m-0 mr-1 text-danger">
                                                        {{{amount: unitDetails.PropertyMortgage, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" v-if="checkEstimation">
                                <h4 class="mw-75 mt-2">{{ i18nT(`Estimation`)}} </h4>
                                <hr/>
                            </b-col>
                            <b-col cols="12" md="12" lg="6"
                                   v-if="evalData.SellPrice">
                                <b-card no-body border-variant="success">
                                    <b-card-body class="p-1">
                                        <div class="d-flex">
                                            <div class="flex-column"><h5>{{ i18nT(`Estimation du prix de vente`) }}</h5>
                                                <div class="flex-column">
                                                    <div class="d-flex align-items-center">
                                                        <h3 class="m-0 mr-1 mb-50 text-success">
                                                        {{
                                                            {
                                                                amount: evalData.SellPrice,
                                                                currency: (unitDetails.PropertyCurrency || 'EUR')
                                                            } | currency
                                                        }}
                                                        </h3>
                                                        <span v-if="unitDetails.PropertyInitialPrice && evalData.SellPrice > unitDetails.PropertyInitialPrice">
                                                            <RtIcon icon="arrow-up"
                                                                    variant="light text-success mb-50 "
                                                                    :size="20"/>
                                                        </span>
                                                    </div>
                                                    <p class="m-0">
                                                        {{ i18nT(`De`) }}
                                                        {{{amount: evalData.SellPriceLow, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                                        {{ i18nT(`à`) }}
                                                        {{{amount: evalData.SellPriceHigh, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                            <b-col cols="12" md="12" lg="6" v-if="evalData.RentalPrice">
                                <b-card no-body border-variant="success">
                                    <b-card-body class="p-1">
                                        <div class="d-flex">
                                            <!--                                                    TODO - GET THESE FROM BACKEND-->
                                            <div class="flex-column"><h5>{{ i18nT(`Estimation du prix du loyer`) }}</h5>
                                                <div class="flex-column">
                                                    <h2 class="m-0 mr-1 mb-50 text-success">
                                                        {{{amount: evalData.RentalPrice, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                                    </h2>

                                                    <p class="m-0">
                                                        {{ i18nT(`De`) }}
                                                        {{{amount: evalData.RentalPriceLow, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                                        {{ i18nT(`à`) }}
                                                        {{{amount: evalData.RentalPriceHigh, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" lg="6" md="12" v-if="evalData.SellPriceM2">
                                <b-card no-body border-variant="success">
                                    <b-card-body class="p-1">
                                        <div class="d-flex">
                                            <div class="flex-column"><h5>{{ i18nT(`Prix au m² moyen`) }}</h5>
                                                <div class="flex-column">
                                                    <h2 class="m-0 mr-1 mb-50 text-success">
                                                        {{{amount: evalData.SellPriceM2, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                                    </h2>

                                                    <p class="m-0">
                                                        {{ i18nT(`De`) }}
                                                        {{{amount: evalData.SellPriceLowM2, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                                        {{ i18nT(`à`) }}
                                                        {{{amount: evalData.SellPriceHighM2, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                            <b-col cols="12" lg="6" md="12" v-if="evalData.RentalPriceM2">
                                <b-card no-body border-variant="success">
                                    <b-card-body class="p-1">
                                        <div class="d-flex">
                                            <div class="flex-column"><h5>{{ i18nT(`Loyer mensuel au m² moyen`) }}</h5>
                                                <div class="flex-column">
                                                    <h2 class="m-0 mr-1 mb-50 text-success">
                                                        {{{amount: evalData.RentalPriceM2, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                                    </h2>

                                                    <p class="m-0">
                                                        {{ i18nT(`De`) }}
                                                        {{{amount: evalData.RentalPriceLowM2, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                                        {{ i18nT(`à`) }}
                                                        {{{amount: evalData.RentalPriceHighM2, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <h4  class="mt-1">{{ i18nT(`Adresse`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        v-if="unitDetails.PropertyAddress"
                                        class="ren-info-cards"
                                    >
                                        <div class="d-flex flex-column">
                                            <p class="m-0">{{ unitDetails.PropertyAddress }}
                                                {{ unitDetails.PropertyAddress2 }}</p>
                                            <p class="m-0">{{ unitDetails.PropertyZip }} {{ unitDetails.PropertyCity }}</p>
                                            <p class="m-0">{{ unitDetails.PropertyCountry }}</p>
                                        </div>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="6">
                                <h4  class="mt-1">{{ i18nT(`Description`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        v-if="checkDescription"
                                        class="ren-info-cards"
                                    >
                                        <p v-if="unitDetails.PropertyComments" class="m-0 mb-1">{{ unitDetails.PropertyComments }}</p>
                                        <p v-if="unitDetails.PropertySize" class="m-0"><b>{{i18nT(`Surface`)}}: </b>  {{ unitDetails.PropertySize}}</p>
                                        <p v-if="unitDetails.PropertyRoomsNum" class="m-0"><b>{{i18nT(`Pièces`)}}: </b>  {{unitDetails.PropertyRoomsNum }}</p>
                                        <p v-if="unitDetails.PropertyRoomsNumChambres === 0 || unitDetails.PropertyRoomsNumChambres"   class="m-0"><b>{{i18nT(`Chambres`)}}: </b>  {{unitDetails.PropertyRoomsNumChambres }}</p>
                                        <p v-if="unitDetails.PropertyRoomsNumBaths === 0 || unitDetails.PropertyRoomsNumBaths" class="m-0"><b>{{i18nT(`Salles de bain`)}}: </b>  {{unitDetails.PropertyRoomsNumBaths }}</p>
                                        <p v-if="unitDetails.PropertyFloor" class="m-0"><b>{{i18nT(`Etage`)}}: </b>  {{unitDetails.PropertyFloor }}</p>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <h4 class="mw-75 mt-2">{{ i18nT(`Equipements`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        v-if="unitExtras.length>0"
                                        class="ren-info-cards"
                                    >
                                        <div v-for="(extra,index) in unitExtras" :key="'extra'+index">
                                            <span class="mr-50">
                                                <RtIcon icon="check" variant="light text-primary"/>
                                            </span>
                                            {{i18nT(extra.label)}}
                                        </div>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="6">
                                <h4 class="mw-75 mt-2 text-wrap">{{ i18nT(`Informations complémentaires`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        class="ren-info-cards d-flex flex-column"
                                    >
                                        <div class="mt-1">
                                            <span class="mr-50">
                                                <RtIcon :icon="unitDetails.PropertyFurnished ? 'check' : 'xmark'"
                                                        :variant="unitDetails.PropertyFurnished ? 'light text-primary' : 'light text-muted'"
                                                />
                                            </span>
                                            <span :class="{'text-muted': !unitDetails.PropertyFurnished}" >
                                                {{ i18nT(`Bien meublé`) }}
                                            </span>
                                        </div>
                                        <div class="mt-1">
                                            <span class="mr-50">
                                                <RtIcon :icon="unitDetails.PropertySmokers ? 'check' : 'xmark'"
                                                        :variant="unitDetails.PropertySmokers ? 'light text-primary' : 'light text-muted'"
                                                />
                                            </span>
                                            <span :class="{'text-muted': !unitDetails.PropertySmokers}">
                                                {{ i18nT(`Fumeurs acceptés`) }}
                                            </span>
                                        </div>
                                        <div class="mt-1">
                                            <span class="mr-50">
                                                <RtIcon :icon="unitDetails.PropertyAnimals ? 'check' : 'xmark'"
                                                        :variant="unitDetails.PropertyAnimals ? 'light text-primary' : 'light text-muted'"
                                                />
                                            </span>
                                            <span :class="{'text-muted': !unitDetails.PropertyAnimals}">
                                                {{ i18nT(`Animaux acceptés`) }}
                                            </span>
                                        </div>

                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <h4 class="mw-75 mt-2">{{ i18nT(`Loyer`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        class="ren-info-cards"
                                        v-if="unitDetails.PropertyRent || unitDetails.PropertyMaintenance"
                                    >
                                        <p class="m-0" v-if="unitDetails.PropertyRent"><b>{{i18nT(`Loyer CC`)}}</b>  {{ {amount:unitDetails.PropertyRent, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency}}</p>
                                        <p class="m-0" v-if="unitDetails.PropertyMaintenance"><b>{{i18nT(`Charges`)}}</b>  {{ {amount:unitDetails.PropertyMaintenance, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency}}</p>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        class="ren-info-cards"
                                        v-else
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="6">
                                <h4 class="mw-75 mt-2 text-wrap">{{ i18nT(`Informations financières`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        v-if="checkFinancialInfo"
                                        class="ren-info-cards d-flex flex-column"
                                    >
                                        <p class="m-0" v-if="unitDetails.PropertyAcquisitionDate"><b>{{i18nT(`Date d'acquisition`)}}: </b>  {{ unitDetails.PropertyAcquisitionDate}}</p>
                                        <p class="m-0" v-if="unitDetails.PropertyInitialPrice || unitDetails.PropertyInitialPrice == 0"><b>{{i18nT(`Prix d’acquisition`)}}: </b>  {{ {amount:unitDetails.PropertyInitialPrice, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency}}</p>
                                        <p class="m-0" v-if="unitDetails.PropertyAcquisitionCosts || unitDetails.PropertyAcquisitionCosts==0"><b>{{i18nT(`Frais d'acquisition`)}}: </b>  {{ {amount:unitDetails.PropertyAcquisitionCosts, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency}}</p>
                                        <p class="m-0" v-if="unitDetails.PropertyFiscalCode"><b>{{i18nT(`Code fiscal`)}}: </b>  {{unitDetails.PropertyFiscalCode }}</p>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        class="ren-info-cards"
                                        v-else
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row v-if="checkConsumption">
                            <b-col cols="6">
                                <h4 class="mw-75 mt-2">{{ i18nT(`Consommations énergétiques`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        v-if="unitDetails.PropertyEnergyConsumptionIndex || unitDetails.PropertyEnergyConsumption"
                                        class="ren-info-cards"
                                    >
                                        <div>
                                            <h4>{{unitDetails.PropertyEnergyConsumptionIndex}} {{unitDetails.PropertyEnergyConsumption}}</h4>
                                            <div v-for="(info, index) in energyConsumptionScale" :key="'scaleInfo' + index" class="d-flex align-items-center">
                                                <div  :class="`ren-arrow-box ${info.color}`" :style="'width:' + info.boxWidth">
                                                    <span>{{ i18nT(info.text) }}</span> <b class="float-right">{{info.energyIndex}}</b>
                                                </div>
                                                <RtIcon v-if="info.energyIndex == unitDetails.PropertyEnergyConsumptionIndex" icon="chevrons-left" variant="light ml-2" :size="18"/>
                                            </div>
                                            <p class="mb-1 mt-1">{{i18nT(`Montant estimé des depenses annuelles d'énergie pour un usage standard.`)}}</p>
                                            <h4>{{i18nT(`De`)}} € {{unitDetails.PropertyEnergyConsumptionAmountFrom}} {{i18nT(`à`)}} € {{unitDetails.PropertyEnergyConsumptionAmountTo}} </h4>
                                            <small>{{i18nT(`Consommations énergétiques (en énergie primaire) pour le chauffage, la production d'eau chaude sanitaire et le refroidissement.`)}}</small>
                                            <br />
                                            <small><b class="mr-50">{{i18nT(`Indice de mesure`)}}:</b>{{i18nT(`kWhEP/m2.an`)}}</small>

                                        </div>

                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="6">
                                <h4 class="mw-75 mt-2 text-wrap">{{ i18nT(`Emissions de gaz à effet de serre`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        v-if="unitDetails.PropertyGreenhouseIndex || unitDetails.PropertyGreenhouse"
                                        class="ren-info-cards"
                                    >
                                        <div>
                                            <h4>{{unitDetails.PropertyGreenhouseIndex}} {{unitDetails.PropertyGreenhouse}}</h4>
                                            <div v-for="(info, index) in greenHouseScale" :key="'scaleInfo' + index" class="d-flex align-items-center">
                                                <div :class="[`ren-arrow-box ${info.color}`, { 'mb-1': index === greenHouseScale.length - 1 }]" :style="'width:' + info.boxWidth">
                                                    <span>{{ i18nT(info.text) }}</span> <b class="float-right">{{info.energyIndex}}</b>
                                                </div>
                                                <RtIcon v-if="info.energyIndex == unitDetails.PropertyGreenhouseIndex" icon="chevrons-left" variant="light ml-2" :size="18"/>
                                            </div>
                                            <small>{{i18nT(`Emissions de gaz à effet de serre (GES) pour le chauffage, la production d'eau chaude sanitaire et le refroidissement.`)}}</small>
                                            <br />
                                            <small><b class="mr-50">{{i18nT(`Indice de mesure`)}}:</b>{{i18nT(`kgeqCO2/m2.an`)}}</small>
                                        </div>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" v-if="unitContacts.length > 0">
                                <h4 class="mw-75 mt-2">{{ i18nT(`Contacts`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        v-if="unitContacts.length>0"
                                        class="ren-info-cards"
                                    >
                                        <div v-for="(contact,index) in unitContacts" :key="'contact'+index">
                                            <contact-card :contact="contact"/>
                                        </div>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <div class="d-flex align-items-center">
                                    <span class="mt-2 mr-50">
                                        <RtIcon icon="link" variant="light" :size="20"/>
                                    </span>
                                    <h4 class="mw-75 mt-2 mb-0">{{ i18nT(`Lien du calendrier de disponibilité`) }} </h4>
                                    <span class="mt-2 ml-50" v-b-tooltip.hover="i18nT(`Lien iCal a utiliser pour la synchronisation avec des services externes tels que Airbnb et Google Calendar.`)"><RtIcon icon="circle-info" variant="light text-info" :size="20" /></span>
                                </div>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        class="ren-info-cards"
                                        v-if="propertyReservationURL"
                                    >
                                            <div
                                                class="mb-2 cursor-pointer"
                                            >
                                                <b-input-group class="mb-50 w-50" v-b-tooltip.hover="propertyReservationURL">
                                                    <b-input-group-prepend is-text>
                                                        <rt-icon icon="calendar" variant="light"/>
                                                    </b-input-group-prepend>
                                                    <b-form-input
                                                        :value="propertyReservationURL"
                                                        disabled
                                                    >
                                                    </b-form-input>
                                                </b-input-group>
                                                <small>{{ i18nT(`Lien iCal a utiliser pour la synchronisation avec des services externes tels que Airbnb et Google Calendar.`) }}</small>
                                            </div>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <div class="d-flex align-items-center">
                                    <span class="mt-2 mr-50">
                                        <RtIcon icon="link" variant="light" :size="20"/>
                                    </span>
                                    <h4 class="mw-75 mt-2 mb-0">{{ i18nT(`Synchronisation des calendriers`) }} </h4>
                                    <span class="mt-2 ml-50" v-b-tooltip.hover="i18nT(`Importez les autres calendriers que vous utilisez et nous actualiserons automatiquement la disponibilité de ce logement.`)"><RtIcon icon="circle-info" variant="light text-info" :size="20" /></span>
                                </div>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        class="ren-info-cards"
                                    >
                                        <p class="mt-2">{{i18nT(`Importez les autres calendriers que vous utilisez et nous actualiserons automatiquement la disponibilité de ce logement.`)}}
                                            <b-link>{{i18nT(`Cliquez ici.`)}}</b-link></p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <div class="d-flex align-items-center">
                                    <span class="mt-2 mr-50">
                                        <RtIcon icon="link" variant="light" :size="20"/>
                                    </span>
                                    <h4 class="mw-75 mt-2 mb-0">{{ i18nT(`Fiche publique`) }} </h4>
                                    <span class="mt-2 ml-50" v-b-tooltip.hover="i18nT(`Lien vers la fiche de votre bien à partager avec des tiers (ex. insérer dans vos annonces immobilières).`)"><RtIcon icon="circle-info" variant="light text-info" :size="20" /></span>
                                </div>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        v-if="propertyPublicURL"
                                        class="ren-info-cards"
                                    >
                                            <div

                                            >
                                                <div class="cursor-pointer d-flex" @click="copyURL">
                                                    <b-input-group class='w-50' v-b-tooltip.hover="propertyPublicURL">
                                                        <b-input-group-prepend is-text>
                                                            <rt-icon icon="link" variant="light"/>
                                                        </b-input-group-prepend>
                                                        <b-form-input
                                                            :value="propertyPublicURL"
                                                            disabled
                                                        >
                                                        </b-form-input>
                                                    </b-input-group>
                                                    <b-button variant="outline-info" size="sm ml-1" @click.stop="redirectToURL">
                                                        {{ i18nT(`Ouvrir le lien`) }}
                                                    </b-button>
                                                </div>

                                                <div class="d-flex mt-1 align-items-center mb-50">
                                                    <b-form-checkbox
                                                        name="check-button"
                                                        switch
                                                        v-model='propertyPublic'
                                                        @change="onChangePublicStatus('property')"
                                                    >
                                                    </b-form-checkbox>
                                                    <span>
                                                    {{ i18nT(`Le bien est `) }}
                                                    <strong>{{ propertyPublic ? i18nT(`Public `) :  i18nT(`Privé `)}}</strong>
                                                    <rt-icon variant="light" :icon="propertyPublic ? 'eye' : 'eye-slash'"/>
                                                    </span>
                                                </div>
                                                <div class="d-flex align-items-center mb-50">
                                                    <b-form-checkbox
                                                        name="check-button"
                                                        switch
                                                        v-model='propertyPublicAddress'
                                                        @change="onChangePublicStatus('address')"
                                                    >
                                                    </b-form-checkbox>
                                                    <span>
                                                        {{ i18nT(`L'adresse postale du bien est `) }}
                                                        <strong>{{ propertyPublicAddress ? i18nT(`Public `) :  i18nT(`Privé `)}}</strong>
                                                        <rt-icon variant="light" :icon="propertyPublicAddress ? 'eye' : 'eye-slash'"/>
                                                    </span>
                                                </div>
                                                <div class="d-flex align-items-center mb-50">
                                                    <b-form-checkbox
                                                        name="check-button"
                                                        switch
                                                        v-model='propertyPublicPhone'
                                                        @change="onChangePublicStatus('phone')"
                                                    >
                                                    </b-form-checkbox>
                                                    <span>
                                                        {{ i18nT(`Le numéro de téléphone est `) }}
                                                        <strong>{{ propertyPublicPhone ? i18nT(`Public `) :  i18nT(`Privé `)}}</strong>
                                                        <rt-icon variant="light" :icon="propertyPublicPhone ? 'eye' : 'eye-slash'"/>
                                                    </span>
                                                </div>
                                            </div>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        class="my-1"
                                    >
                                        <small>{{ i18nT(`Activez le flyer électronique si vous êtes à la recherche de locataire. Les candidats pourraient postuler à votre annonce directement via notre site.`) }}</small>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                cols="12" md="6"
            >
                <unit-detail-tabs :unit-data="tabsData"/>
            </b-col>
        </b-row>
        <b-modal
            id="modal-map-carousel-full"
            hide-footer
            size="xl"
            content-class="modal-fullscreen"
            @close="resetCarousel"
        >
            <map-carousel :images="unitImages" :address="unitAddress" :full="true" :selected-photo="selectedPhoto" btnId="full"/>

        </b-modal>
    </div>
</template>

<script>
import {
    BAvatar,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BCardHeader,
    BCardBody,
    BFormTextarea,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BForm,
    BFormRating,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    BModal,
    BProgress,
    VBTooltip,
    BCarousel,
    BCarouselSlide,
} from 'bootstrap-vue'
import router from '@/router'
import { dictToSelectArray } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAuth from '@/auth/useAuth'
import moment from 'moment';
import EllipsisDropdown from "@core/components/EllipsisDropdown.vue";
import Ripple from 'vue-ripple-directive'
import MapCarousel from "@/views/components/mapCarousel/MapCarousel.vue";
import {ValidationProvider} from "vee-validate";
import ContactCard from "@/views/components/contactCard/ContactCard.vue";
import UnitDetailTabs from "./UnitDetailTabs.vue"


export default {
    components: {
        ContactCard,
        ValidationProvider,
        MapCarousel,
        EllipsisDropdown,
        BAvatar,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BCardHeader,
        BCardBody,
        BFormTextarea,
        BTabs,
        BTab,
        BDropdown,
        BDropdownItem,
        BBadge,
        BLink,
        BForm,
        BFormRating,
        BFormSelect,
        BFormInput,
        BFormCheckbox,
        BModal,
        BProgress,
        BCarousel,
        BCarouselSlide,
        UnitDetailTabs
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data() {
        return {
            unitDetails: {},
            unitExtras: [],
            unitImages: [],
            unitContacts: [],
            activationLink: '',
            curUnitId: router.currentRoute.params.id,
            tabsData: {},
            energyConsumptionScale: [
                {color:'dark-green', boxWidth: '50px', text: '< 70', energyIndex: 'A'},
                {color:'green', boxWidth: '70px', text: '70 à 110', energyIndex: 'B'},
                {color:'light-green', boxWidth: '90px', text: '110 à 180', energyIndex: 'C'},
                {color:'yellow', boxWidth: '110px', text: '180 à 250', energyIndex: 'D'},
                {color:'orange', boxWidth: '130px', text: '250 à 330', energyIndex: 'E'},
                {color:'dark-orange', boxWidth: '150px', text: '330 à 420', energyIndex: 'F'},
                {color:'red', boxWidth: '170px', text: ' > 420', energyIndex: 'G'}
            ],
            greenHouseScale: [
                {color:'violet-a', boxWidth: '50px', text: '< 6', energyIndex: 'A'},
                {color:'violet-b', boxWidth: '70px', text: '6 à 11', energyIndex: 'B'},
                {color:'violet-c', boxWidth: '90px', text: '11 à 30', energyIndex: 'C'},
                {color:'violet-d', boxWidth: '110px', text: '30 à 50', energyIndex: 'D'},
                {color:'violet-e', boxWidth: '130px', text: '50 à 70', energyIndex: 'E'},
                {color:'violet-f', boxWidth: '150px', text: '70 à 100', energyIndex: 'F'},
                {color:'violet-g', boxWidth: '170px', text: '> 100', energyIndex: 'G'}
            ],
            propertyReservationURL: `https://www.rentila.com/reservations/ical/${router.currentRoute.params.id}`, //TODO change when reservations are up
            // propertyPublicURL: `https://www.stage-app.rentila.com/${router.currentRoute.params.id}`, //TODO change when public page is ready
            propertyPublic: false,
            propertyPublicAddress: false,
            propertyPublicPhone: false,
            unitType: '',
            evalData: {},
            selectedPhoto: null,
            mapCarouselKey: 0,

        }
    },
    created() {
        this.$http.get(`landlord/properties/details?id=${this.curUnitId}`)
            .then(({data}) => {
                this.tabsData = data.data;
                this.unitDetails = data.data.property;
                this.unitExtras = data.data.extras || [];
                this.unitImages = data.data.images.sort((a,b)=>b.PropertyCoverImage - a.PropertyCoverImage) || [];
                console.log(this.unitImages)
                this.unitContacts = data.data.contacts || [];
                this.propertyPublic = !!data.data.property.PropertyPublic;
                this.propertyPublicAddress = !!data.data.property.PropertyPublicAddress;
                this.propertyPublicPhone = !!data.data.property.PropertyPublicPhone;
                this.unitType = data.data['property_types'].find(type => type.id === this.unitDetails.PropertyTypeID).label || ''
            })

        this.$http.get(`landlord/properties/evaluationData?property_id=${this.curUnitId}`)
            .then(({data}) => {
               this.evalData = data.data || {};
            })


    },
    computed:{
        unitAddress(){
            return (this.unitDetails.PropertyAddress || '') + ' ' + (this.unitDetails.PropertyPostalCode || '') + ' ' + (this.unitDetails.PropertyCity || '') + ' ' + (this.unitDetails.PropertyCountry || '')
        },
        checkConsumption(){
            return this.unitDetails.PropertyEnergyConsumptionIndex || this.unitDetails.PropertyEnergyConsumption || this.unitDetails.PropertyGreenhouseIndex || this.unitDetails.PropertyGreenhouse
        },
        checkFinancialInfo(){
            return this.unitDetails.PropertyAcquisitionDate || this.unitDetails.PropertyInitialPrice || this.unitDetails.PropertyAcquisitionCosts || this.unitDetails.PropertyFiscalCode
        },
        checkDescription(){
            return this.unitDetails.PropertyComments || this.unitDetails.PropertySize || this.unitDetails.PropertyConstructionYear || this.unitDetails.PropertyRoomsNum || this.unitDetails.PropertyRoomsNumChambres || this.unitDetails.PropertyRoomsNumBaths || this.unitDetails.PropertyFloor
        },
        checkEstimation(){
            return this.evalData.SellPrice || this.evalData.RentPrice || this.evalData.SellPriceM2 || this.evalData.RentPriceM2
        },
        propertyPublicURL(){
          return  `${window.location.protocol}//${window.location.host}/properties/property/${this.curUnitId}`
        }
    },
    methods: {
        openModal(photo){
            this.selectedPhoto = photo;
            this.$bvModal.show('modal-map-carousel-full')
        },
        resetCarousel(){
            this.mapCarouselKey += 1;
        },
        onDelete() {
            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Veuillez confirmer la suppression de ce bien. Cette action entrainera la suppression de tous les données qui lui sont liés.`),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.i18nT(`Annuler`),
                confirmButtonText: this.i18nT(`Confirmer`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if(result.value) {
                    this.$http.delete(`landlord/properties?id=${this.curUnitId}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Succès`),
                                text: this.i18nT(`Supprimé avec succès`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.$router.push({ name:'properties'})
                        })
                        .catch((err) => {
                            this.$swal({
                                icon: 'error',
                                title: 'Error!',
                                text: this.errorMessage(err),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.$router.push({ name:'buildings'})
                        })
                }
            })
        },
        onEdit(){
            this.$router.push({ name: 'edit-property', params: { id: this.curUnitId } })
        },
        copyURL(){
            navigator.clipboard.writeText(this.propertyPublicURL);
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    icon: 'InfoIcon',
                    title: `Url copied`,
                    variant: 'primary'
                }
            })
        },
        redirectToURL(){
            window.open(this.propertyPublicURL, '_blank');
        },
        onChangePublicStatus(type) {

            const formData = new FormData();
            formData.append('id', this.curUnitId);
            if (type === 'property') {
                formData.append('field', 'PropertyPublic');
                formData.append('value', this.propertyPublic ? 1 : 0);
            } else if (type === 'address') {
                formData.append('field', 'PropertyPublicAddress');
                formData.append('value', this.propertyPublicAddress ? 1 : 0);
            } else if (type === 'phone') {
                formData.append('field', 'PropertyPublicPhone');
                formData.append('value', this.propertyPublicPhone ? 1 : 0);
            }

            this.$http.post(`landlord/properties/public`, formData, {showLoader:false}).then(() => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: this.i18nT(`Company public status changed`),
                    icon: 'InfoIcon',
                    variant: 'success'
                    }
                })
            })
        },


    },
}

</script>

<style>

.ren-info-cards {
    min-height: 120px;
}

.ren-task-box:hover {
    transform: translateY(-2.5px);
    transition: transform 0.3s ease-in-out;
}

.ren-title {
    transition: transform 0.3s ease-in-out;
    opacity: .7;
}

.ren-title:hover {
    opacity: 1;
    transform: translateY(-2.5px);
}

.nav-item > a[aria-selected="true"] .ren-title {
    opacity: 1;
}
.modal-fullscreen{
    max-height: 90vh;
}

.ren-property-rooms {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}
.ren-property-info-card{
    background-color: #f8f8f8;
    border: none !important;
}

.ren-arrow-box{
    position: relative;
    background: #88b7d5;
    height: 20px;
    padding: 2px 5px;
    font-size: 12px;
    line-height: 16px;
    margin: 2px;
    color: white !important;
}

.ren-arrow-box:after {
    left: 100%;
    top: 50%;
    border: 10px solid transparent;
    content: " ";
    height: 16px;
    width: 0;
    position: absolute;
    pointer-events: none;
    margin-top: -10px;
}


/* ARROW BOX ENERGY COLORS */
.dark-green {
    background: #4CAF50;
}

.green{
    background: #54ad16;
}

.light-green{
    background: #c9d400;
}

.yellow{
    background: rgba(243, 230, 52, 0.84);
}

.orange{
    background: #fdbc00;
}

.dark-orange{
    background: #ed6900;
}

.red{
    background: #e60006;
}


.dark-green:after {
    border-left-color: #4CAF50;
}

.green:after{
    border-left-color: #54ad16;
}

.light-green:after{
    border-left-color: #c9d400;
}

.yellow:after{
    border-left-color: rgba(243, 230, 52, 0.84);
}

.orange:after{
    border-left-color: #fdbc00;
}

.dark-orange:after{
    border-left-color: #ed6900;
}

.red:after{
    border-left-color: #e60006;
}

/* ARROW BOX GREEN HOUSE COLORS */

.violet-a{
    background: #f0e1fb;
}

.violet-b{
    background: #e2c1fa;
}

.violet-c{
    background: #d5a7f7;
}

.violet-d{
    background: #cc92f5;
}

.violet-e{
    background: #bb6df2;

}

.violet-f{
    background: #a845ee;
}

.violet-g{
    background: #8b00e3;
}

.violet-a:after{
    border-left-color: #f0e1fb;
}

.violet-b:after{
    border-left-color: #e2c1fa;
}

.violet-c:after{
    border-left-color: #d5a7f7;
}

.violet-d:after{
    border-left-color: #cc92f5;
}

.violet-e:after{
    border-left-color: #bb6df2;

}

.violet-f:after{
    border-left-color: #a845ee;
}

.violet-g:after{
    border-left-color: #8b00e3;
}




</style>
<template>
    <b-card 
        no-body
    >
        <b-card-body>
            <b-row>
                <b-col
                    cols="6"
                >
                    <h4>{{ i18nT(`Documents`) }}</h4>
                </b-col>
                <b-col
                    cols="6"
                    class="pr-1 d-flex justify-content-end"
                >
                    <div class="custom-search d-flex justify-content-end">
                        <b-form-group>
                            <div class="input-group input-group-merge" role="group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <RtIcon variant="light" icon="search"/>
                                    </div>
                                </div>
                                <b-form-input
                                    v-model.lazy="searchQuery"
                                    :placeholder="i18nT(`Chercher`)"
                                    class="d-inline-block"
                                    type="text"
                                />
                            </div>
                        </b-form-group>
                    </div>
                </b-col>
                <b-col
                    cols="12"
                    class="mb-1"
                >

                    <b-table
                        ref="refDocumentsTabListTable"
                        :key="'DocumentsTable' + tableColumns.length"
                        :fields="tableColumns"
                        :items="fetchDocuments"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        primary-key="id"
                        responsive
                    >
                        <template #cell(DocumentName)="data">
                            <div class="d-flex align-items-center">
                                <div class="cursor-pointer" @click="sortByType(data.item.DocumentType)">
                                    <!-- <b-avatar size="5em" :variant="getIconVariant(data.item)">
                                        <rt-icon :icon="getIcon(data.item)" variant="thin" :size="34"/>
                                    </b-avatar> -->
                                    <rt-icon :icon="`${getIcon(data.item)} ${getIconVariant(data.item)}`" variant="thin" :size="34"/>
                                </div>
                                <div class="d-flex flex-column">
                                    <h6 class="m-0 ml-1 ">
                                        <b-link :href="data.item['download_url']">{{ data.item.DocumentName }}</b-link>
                                    </h6>
                                    <small class="m-0 ml-1 mt-25">{{ data.item.DocumentCreateTime | date }}</small>
                                    <p class="m-0 ml-1 mt-25">{{ data.item.DocumentDescription }}</p>
                                </div>
                            </div>

                        </template>
                        <template #cell(DocumentIsShared)="data">
                            <div class="d-flex align-items-center justify-content-center">
                                <b-badge
                                    v-b-tooltip="getTooltipLabel(data.item.DocumentIsShared, data.item['_can_edit'])"
                                    :variant="statusLabelClass(data.item.DocumentIsShared, data.item['_can_edit'])"
                                    pill
                                >
                                        {{
                                            statusLabel(data.item.DocumentIsShared, data.item['_can_edit'], data.item)
                                        }}
                                </b-badge>
                            </div>
                        </template>

                        <template #cell(action)="data">
                            <b-dropdown
                                no-caret
                                toggle-class="p-0"
                                variant="link"
                                dropleft
                            >
                                <template #button-content>
                                    <RtIcon variant="light" icon="ellipsis-vertical align-middle text-body ml-1" :size="18"/>
                                </template>
                                <b-dropdown-item
                                    :to="{name:'edit-property', params:{id: data.item.BuildingID}}"
                                >
                                    <RtIcon variant="light" icon="edit" />
                                    <span class="align-middle ml-50">{{ i18nT(`Modifier`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    :disabled="!data.item['_can_edit']"
                                    @click="onDelete(data.item)"
                                    :class="{'cursor-not-allowed': !data.item['_can_edit']}"
                                >
                                    <RtIcon variant="light" icon="trash text-danger"/>
                                    <span class="align-middle ml-50 text-danger">
                                        {{i18nT(`Supprimer`)}}
                                    </span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>

                    </b-table>
                    <b-row v-if="documents.length > 0">
                        <b-col
                            class="
                                d-flex
                                align-items-end
                                justify-content-end 
                            "
                            cols="12"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :per-page="perPage"
                                :total-rows="totalDocuments"
                                class="mb-0 mt-1 mt-sm-0"
                                first-number
                                last-number
                                next-class="next-item"
                                prev-class="prev-item"
                            >
                                <template #prev-text>
                                    <RtIcon variant="light" icon="chevron-left" :size="16"/>
                                </template>
                                <template #next-text>
                                    <RtIcon variant="light" icon="chevron-right" :size="16"/>
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>

import {
    BTable,
    BRow,
    BCol,
    BCard,
    BDropdown,
    BDropdownItem,
    BProgress,
    BButton,
    BLink,
} from 'bootstrap-vue'
import useDocumentsTabList from './tableDocumentsTabList'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from '@/router'

export default {
    components: {
        BTable, 
        BRow,
        BCol,
        BCard,
        BDropdown,
        BDropdownItem,
        BProgress,
        BButton,
        BLink,
    },
    data() {
        return {
            formatDic: [
                {type: 'pdf', icon: 'file-pdf', background: 'text-danger'},
                {type: 'docx', icon: 'file-doc', background: 'text-info'},
                {type: 'doc', icon: 'file-doc', background: 'text-info'},
                {type: 'word', icon: 'file-word', background: 'text-info'},
                {type: 'openoffice', icon: 'file-lines', background: 'text-info'},
                {type: 'jpg', icon: 'file-jpg', background: 'text-warning'},
                {type: 'png', icon: 'file-png', background: 'text-secondary'},
                {type: 'xls', icon: 'file-xls', background: 'text-success'},
                {type: 'xlsx', icon: 'file-xls', background: 'text-success'},
            ],
        }
    },
    setup() {

        const {
            fetchDocuments,
            activeDocumentsCount,
            archiveDocumentsCount,
            tableColumns,
            perPage,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentsTabListTable,
            totalDocuments,
            listMode,
            documents,
            refetchData,
        } = useDocumentsTabList();

        return {
            fetchDocuments,
            activeDocumentsCount,
            archiveDocumentsCount,
            tableColumns,
            perPage,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentsTabListTable,
            totalDocuments,
            listMode,
            documents,
            refetchData,
        }
    },
    methods: {
        getIcon(document){
            const findIcon = this.formatDic.find((format) => format.type === document.DocumentType);
            if(findIcon){
                return findIcon.icon;
            } else {
                return 'file-text';
            }
        },
        getIconVariant(document){
            const findIcon = this.formatDic.find((format) => format.type === document.DocumentType);
            if(findIcon){
                return findIcon.background;
            } else {
                return 'text-secondary';
            }
        },
        getTooltipLabel(isShared, canEdit) {
            if (isShared == 1) {
                if (canEdit) {
                    return this.i18nT(`Document partagé avec le(s) locataire(s)`);
                }
                return this.i18nT(`Document partagé par le(s) locataire(s)`);
            } else {
                return this.i18nT(`Le document n'est pas partagé`);
            }
        },
        statusLabel(isShared, canEdit) {
            if (isShared == 1) {
                if (canEdit) {
                    return this.i18nT("Partagé");
                }
                return this.i18nT("Partagé");
            } else {
                return this.i18nT("Privé");
            }
        },
        statusLabelClass(isShared, canEdit) {
            if (isShared == 1) {
                if (canEdit) {
                    return "light-success";
                }
                return "light-info";
            } else {
                return "light-secondary";
            }
        },
        onDelete(document) {
            this.$swal({
                  title: this.i18nT(`Avertissement`),
                  text: this.i18nT(`Veuillez confirmer la suppression.?`),
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: this.i18nT(`Confirmer`),
                  cancelButtonText: this.i18nT(`Annuler`),
                  customClass: {
                      confirmButton: 'btn btn-primary',
                      cancelButton: 'btn btn-outline-danger ml-1'
                  },
                  buttonsStyling: false
            }).then(result => {
                if(result.value) {

                    const modal = this.$router.currentRoute.meta.navActiveLink
                    const paramName = this.$router.currentRoute.name.split('-')[1].charAt(0).toUpperCase() + this.$router.currentRoute.name.split('-')[1].slice(1) + "ID"
                    const id = this.$router.currentRoute.params.id

                    this.$http.delete(`landlord/${modal}/document?${paramName}=${id}&DocumentID=${document.DocumentID}`).then(()=>{
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.i18nT(`Supprimé avec succès !`),
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })

                        this.refetchData()
                    }).catch(err => {
                        this.$swal({
                            icon: 'error',
                            title: 'Error!',
                            text: this.errorMessage(err),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                    })
                }
            }).catch(err => {
                this.$swal({
                    icon: 'error',
                    title: 'Error!',
                    text: this.errorMessage(err),
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
            })
        }
    },
    watch: {
        totalDocuments: {
            handler: function(newVal, oldVal) {
                if(newVal !== oldVal && oldVal !== 0) {
                    this.$emit('countChanged', newVal)
                }
            },
        }
    }
}
</script>
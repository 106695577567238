import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'
import store from '@/store'
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import router from "@/router";
const { i18n } = useI18nUtils();
function i18nT(str){
	return i18n(str)
}

export default function useDocumentsTabList(){

	const refDocumentsTabListTable = ref(null);

	// Table Handlers

	const tableColumns = [
		{ key: 'DocumentName', label: i18nT(`Titre`), sortable: true,  thClass: 'pl-1 bg-transparent border-bottom', tdClass: 'pl-1' },
        { key: 'DocumentIsShared', label: i18nT(`Etat`), sortable: true, thClass:'text-center bg-transparent border-bottom'},
		{
			key: 'action',
			label: i18nT(`Actions`),
			thClass: 'tbl-actions bg-transparent',
			tdClass: 'sticky-column',
			stickyColumn: true,
		},
	]

	const perPage = ref(25)
	const totalDocuments = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10, 25, 50, 100]
	const sortBy = ref('')
	const isSortDirDesc = ref(true)
	const searchQuery = ref('')
	const documents = ref([])
	const listMode = ref('active')
	const activeDocumentsCount = ref(0);
	const archiveDocumentsCount = ref(0);

	const query = router.currentRoute

	let timeoutId = null;
	const refetchData = () => {

		refDocumentsTabListTable.value.refresh()
	}

	watch(
		[
			currentPage,
			perPage,
			listMode,
		],
		() => {
			refetchData()
		}
	)

	watch(
		searchQuery,
		() => {
			clearTimeout(timeoutId)
			timeoutId = setTimeout(() => {
				refetchData()
			}, 500)
		}
	)

	const fetchDocuments = (ctx, callback) => {
		const params = {
			page: currentPage.value,
			show_per_page: perPage.value,
			sort_by: sortBy.value,
			sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
			search: searchQuery.value,
			mini_table: 1,
		}
        
        const filter = query.name.split('-')[1].charAt(0).toUpperCase() + query.name.split('-')[1].slice(1) + "ID"
        params[`filter[${filter}]`] = query.params.id

		if (listMode.value == 'active') {
			params['active'] = 1
		}



		axios.get('landlord/documents', {
			params
		}).then(({data}) => {
			if (data.data.documents) {
				documents.value = data.data.documents
				callback(data.data.documents)
			} else {
				documents.value = []
				callback([])
			}
			totalDocuments.value = data.data.pagination.records_count;
			activeDocumentsCount.value = data.data['records_count_active'];
			archiveDocumentsCount.value = data.data['records_count_archive'];
		}).catch(err => {
			store.commit('app/SET_GLOBAL_MESSAGE', {message: err.response.data['error_message'], type: 'danger'})
		})
	}

	return {
		fetchDocuments,
		activeDocumentsCount,
		archiveDocumentsCount,
		tableColumns,
		perPage,
		currentPage,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refDocumentsTabListTable,
		totalDocuments,
		listMode,
		documents,
		refetchData,
	}
}


<template>
    <b-card no-body border-variant="primary">
        <b-card-body :class="`note-bg-${note.NoteColour}`">
            <b-row>
                <b-col
                    cols="12"
                    md="12"
                    lg="1"
                    sm="1"
                    class="d-flex justify-content-center align-items-center"
                >
                    <rt-icon icon="note-sticky" variant="thin ml-50" :size="40" />
                </b-col>
                <b-col
                    md="12"
                    lg="10"
                    sm="10"
                    cols="12"
                    class="pl-1"
                >
                    <h5 v-b-tooltip.hover.topleft="note.NoteText" class="ren-note-text text-truncate w-75 mb-0">{{ note.NoteText }}</h5>
                    <small>{{ note.NoteCreateTime | date }}</small>
                </b-col>
                <b-col
                    cols="12"
                    md="12"
                    lg="1"
                    sm="1"
                >
                    <b-button
                        variant="outline-danger"
                        class="pill float-right ml-1"
                        @click="deleteNote"
                    >
                        <rt-icon icon="trash text-danger" variant="light" :size="16"/>
                    </b-button>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>

</template>

<script>
import {
    BButton,
    BCard,
    BCardBody,
    BCol,
    BRow,
    VBTooltip
} from "bootstrap-vue";

export default{
    components: {
        BCol,
        BRow,
        BButton,
        BCard,
        BCardBody,
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    props: {
        note: {
            type: Object,
            default: null
        },
    },
    methods: {
        deleteNote() {
            this.$emit('delete');
        }
    }

}
</script>

<style lang="scss">

.note-bg-yellow{
    background-color: #FFF3BD;
    border-radius: 5px;
}
.dark-layout .ren-note-text{
    color: #96979BFF;
}

</style>
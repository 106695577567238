<template>
    <b-card no-body border-variant="primary">
        <b-card-body>
            <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-between align-items-center">
                    <span
                        :class="{'text-primary': isCompleted && selectedTask.TaskID === task.TaskID}"
                        @click="completeTask"
                    >
                        <RtIcon
                            :size="25"
                            variant="solid"
                            icon="check-circle mr-1 cursor-pointer ren-task-box"
                        />
                    </span>
                    <div class="d-flex flex-column flex-lg-row">
                        <span class="mr-1 text-primary">{{ task.TaskSubject }}</span>
                        <span>{{ task.TaskDeadline | date }}</span>
                    </div>
                </div>
                <div>
                    <span v-if="task.TaskDeliveryType !== 'once'" class="badge badge-success">
                        {{ i18nT('RÉPÉTER')}}
                    </span>
                    <span v-else-if="task.TaskActive === 1 && compareDate(task.TaskDeadline)" class="badge badge-primary">
                        {{ i18nT(`ACTIVE`)}}
                    </span>
                    <span v-else-if="task.TaskActive === 1 && !compareDate(task.TaskDeadline)" class="badge badge-warning">
                        {{ i18nT(`EN RETARD`)}}
                    </span>
                </div>
            </div>
        </b-card-body>
    </b-card>
</template>


<script>
import {
    BCard,
    BCardBody,
    VBTooltip
} from "bootstrap-vue";
import moment from "moment/moment";

export default{
    components: {
        BCard,
        BCardBody
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    props: {
        task: {
            type: Object,
            default: null
        },
        isCompleted: {
            type: Boolean,
            default: false
        },
        selectedTask: {
            type: Object,
            default: null
        },
    },
    methods: {
        compareDate(date) {

            const currentDate = moment().startOf('day')
            const inputDate = moment(date)

            if(inputDate.isBefore(currentDate)) {
                return false
            }
            else if (inputDate.isAfter(currentDate)) {
                return true
            }
            else {
                return true
            }
        },
        completeTask(){
            this.$emit('complete', this.task)
        }
    }
}
</script>
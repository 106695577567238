<template>
    <b-card no-body border-variant="primary">
        <b-card-body class="pt-75 pb-75">
            <b-row
                >
                <b-col
                    cols="12"
                    md="12"
                    lg="1"
                    class="d-flex justify-content-center align-items-center"
                >
                        <rt-icon :icon="icon" variant="thin ml-50" :size="35"/>
                </b-col>
                <b-col
                    lg="10"
                    md="12"
                    cols="12"
                    class="pl-1 mt-25"
                >
                    <b-link class="cursor-pointer text-truncate w-75 mb-0"><strong>{{ title }}</strong></b-link><br />
                    <small>{{ date | date }}</small>
                    <p
                        class="text-truncate m-0"
                    >
                      {{ subTitle ? i18nT(subTitle) : i18nT(`Pas de description`) }}
                    </p>
                </b-col>
                <b-col
                    cols="12"
                    lg="1"
                    md="12"
                    sm="12"
                    class="mt-25"
                >
                    <b-badge
                        v-if="compareDate(date)"
                        variant="primary"
                        class="pill float-right mb-1 ml-1"
                    >
                      <p class="m-0">{{ i18nT(`ACTIVE`) }}</p>
                    </b-badge>
                    <b-badge
                        v-else
                        variant="warning"
                        class="pill float-right mb-1 ml-1"
                    >
                      <p class="m-0">{{ i18nT(`EN RETARD`) }}</p>
                    </b-badge>
                </b-col>
            </b-row>
        </b-card-body>
  </b-card>
</template>

<script>



import {
  BAvatar,
  VBTooltip,
  BCard,
  BCardBody,
  BLink,
  BCol,
  BRow,
  BBadge,
} from "bootstrap-vue";
import  Ripple  from "vue-ripple-directive";
import moment from 'moment'


export default  {
    components: {
      BAvatar,
      BCard,
      BRow,
      BCol,
      BBadge,
      BCardBody,
      BLink
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip

    },
    props: {
        maintenance: {
            type: Object,
            default: null,
        },
    },
    data() {
      return {
            title: '',
            subTitle: '',
            date: '',
            icon: '',
      }
    },
    methods: {
        compareDate(date) {

            const currentDate = moment().startOf('day')
            const inputDate = moment(date)

            if(inputDate.isBefore(currentDate)) {
                return false
            } 
            else if (inputDate.isAfter(currentDate)) {
                return true
            }
            else {
                return true
            }
        },
    },
    watch: {
        maintenance: {
            handler: function (val) {
                this.title = val.InterventionSubject
                this.subTitle = val.InterventionTypeName
                this.date = val.InterventionDueDate
                this.icon = val.InterventionTypeIcon
            },
            immediate: true,
        },
    }
}
</script>


<template>
    <b-card no-body border-variant="primary">
            <b-row class="m-1"
            >
                <b-col
                    cols="12"
                    lg="1"
                    md="12"
                    class="justify-content-center align-items-center d-flex pr-0 mb-50"

                >
                    <div class="d-flex align-items-center justify-content-center">
                        <rt-icon icon="file" variant="thin" :size="50"/>
                        <span class="ren-contract-type-icon">
                        <rt-icon :icon="currentContractTypeIcon" variant="solid" :size="16"></rt-icon>
                        </span>
                    </div>
                </b-col>
                <b-col
                    md="12"
                    lg="10"
                    cols="12"
                    class="pl-50"
                >
                    <div class="flex-column"><h4 class="cursor-pointer" @click="onEdit">{{ i18nT(currentContractTypeName)}}</h4>
                        <div v-if="currentContract.PropertiesContractType == '3'" class="flex-column d-flex mb-50">
                            <div class="d-flex mb-50"><span>{{ i18nT(`Consommations énergétiques`) }}</span>
                                <b-badge :class="getConsumptionBadgeClass(currentContract.PropertyEnergyConsumptionIndex)" pill class="ml-50">{{ currentContract.PropertyEnergyConsumption }}
                                    {{ currentContract.PropertyEnergyConsumptionIndex }}
                                </b-badge>
                            </div>
                            <div class="d-flex">{{i18nT(`Emissions de gaz à effet de serre`)}}
                                <b-badge v-if="currentContract.PropertyGreenhouseIndex" :class="getConsumptionBadgeClass(currentContract.PropertyGreenhouseIndex)" pill class="ml-50">{{ currentContract.PropertyGreenhouse }}
                                    {{ currentContract.PropertyGreenhouseIndex }}
                                </b-badge>
                            </div>
                        </div>
                        <div
                            v-else
                            class="text-truncate mb-50"
                        >
                        {{ currentContract.PropertiesContractText }}
                        </div>
                    </div>

                    <span>{{ currentContract.PropertiesContractDate | date }}</span>
                    <div class="flex-column d-flex mt-50">
                        <b-link v-if="currentContract['download_url']" :href="currentContract['download_url']"
                                target="_blank">
                            <rt-icon icon="paperclip" variant="light"/>
                            {{ currentContract['download_name'] }}
                        </b-link>

                    </div>
                </b-col>
                <b-col
                    cols="12"
                    lg="1"
                    md="12"
                    class="d-flex justify-content-end align-items-center"
                >
                    <div v-if="editable">
                        <b-button-group
                            size="sm"
                        >
                            <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                @click="onEdit"
                            >
                                <rt-icon icon="edit" variant="light" :size="16"/>
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                            >
                                <rt-icon icon="trash" variant="light" :size="16"/>
                            </b-button>
                        </b-button-group>
                    </div>
                </b-col>
            </b-row>
    </b-card>
</template>

<script>



import {
    BAvatar,
    VBTooltip,
    BCard,
    BCardBody,
    BLink,
    BCol,
    BRow,
    BBadge,
    BImg,
} from "bootstrap-vue";
import  Ripple  from "vue-ripple-directive";


export default  {
    components: {
        BAvatar,
        BCard,
        BRow,
        BCol,
        BBadge,
        BCardBody,
        BLink,
        BImg,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip

    },
    props: {
        contract: {
            type: Object,
            default: null,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        contractTypes: {
            type: Array,
            default: null,
        }
    },
    data() {
        return {
            currentContract: {},
            currentContractTypeIcon:"badge text-warning",
            // currentContractTypeIcon: {icon:"badge", variant:"light-warning"},
            icon: 'file-text',
            currentContractTypeName: '',
        }
    },
    methods: {
        getConsumptionBadgeClass(index){
            switch(index){
                case 'A':
                    return 'badge-success';
                case 'B':
                    return 'badge-success';
                case 'C':
                    return 'badge-light-warning';
                case 'D':
                    return 'badge-light-warning';
                case 'E':
                    return 'badge-warning';
                case 'F':
                    return 'badge-light-danger';
                case 'G':
                    return 'badge-danger';
                default:
                    return '';
            }
        },
        onEdit() {
            this.$emit("edit", this.currentContract);
        },
    },
    watch: {
        contract: {
            handler: function (val) {
                    this.currentContract = val;
                    const findContractType = this.contractTypes.find((type) => type.value == val.PropertiesContractType);
                    if(findContractType){
                        this.currentContractTypeName = findContractType.text;
                    }
                    switch(this.currentContract.PropertiesContractType){
                        case 1:
                        case 14:
                            this.currentContractTypeIcon = 'shield-check text-success';
                            break;
                        case 16:
                            this.currentContractTypeIcon = 'bolt text-info';
                            break;
                        case 17:
                            this.currentContractTypeIcon = 'fire text-info';
                            break;
                        case 18:
                            this.currentContractTypeIcon = 'phone text-info';
                            break;
                        case 19:
                            this.currentContractTypeIcon = 'wifi text-info';
                            break;
                        case 20:
                            this.currentContractTypeIcon = 'faucet-drip text-info';
                            break;
                    }
                    // switch(this.currentContract.PropertiesContractType){
                    //     case 1:
                    //     case 14:
                    //         this.currentContractTypeIcon = {icon:'shield-check', variant:'success'};
                    //         break;
                    //     case 16:
                    //         this.currentContractTypeIcon = {icon:'bolt', variant:'info'};
                    //         break;
                    //     case 17:
                    //         this.currentContractTypeIcon = {icon:'fire', variant:'info'};
                    //         break;
                    //     case 18:
                    //         this.currentContractTypeIcon = {icon:'phone', variant:'info'};
                    //         break;
                    //     case 19:
                    //         this.currentContractTypeIcon = {icon:'wifi', variant:'info'};
                    //         break;
                    //     case 20:
                    //         this.currentContractTypeIcon =  {icon:'faucet-drip', variant:'info'};
                    //         break;
                    // }


            },
            immediate: true,
        },
    }
}
</script>
<style lang="scss">


.ren-contract-type-icon{
    //bottom: 24px;
    //left: 5px;
    right: 26px;
    top: 10px;
    z-index: 2;
    position: relative;
}
.dark-layout .ren-contract-type-icon {
    background-color: #283046;
}

//CUSTOM FILE ICON FROM ORIGINAL RENTILA
.ren-file-type-icon {
    display: block;
    width: 28px;
    height: 36px;
    background: #f9f9f9;
    position: relative;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    box-shadow: inset 1px 1px 0 0 #ffffff, inset -1px -1px 0 0 #ffffff, inset 0 10px 20px -10px rgba(0, 0, 0, 0.05);
}

.ren-file-type-icon .corner {
    display: block;
    position: absolute;
    top: -1px;
    right: -1px;
    width: 0;
    height: 0;
    border-left: 12px solid #eeeeee;
    border-top: 12px solid #ffffff;
    box-shadow: -1px 1px 0px 0 #e6e6e6;
}

</style>


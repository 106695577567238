<template>
    <b-tabs
        pills
    >
        <b-tab
            :title="i18nT(`Finances`)"
        >
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="coins"/>
                    </div>
                    <p class="m-0">
                        {{ i18nT(`Finances`)}}
                    </p>
                </div>
            </template>
            <b-card
                no-body
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Finances`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col cols="12">
                            <hr class="mt-0 mb-2"/>
                        </b-col>
                        <b-col md="4" cols="12">
                            <v-select
                                v-model="year"
                                :options="years"
                                :clearable="false"   
                                label="text"
                                :reduce="(e) => e.value"  
                                @input="getStats"                                  
                            />
                        </b-col>
                        <b-col cols="12">
                            <hr class="mt-2 mb-2"/>
                        </b-col>
                        <b-col cols="12" class="my-2">
                            <vue-apex-charts
                                id="properties-income-chart"
                                type="area"
                                height="400"
                                :series="areaSeries"
                                :options="areaChartOptions"
                                ref="areaChart"
                            />
                        </b-col>
                        <b-col
                            :lg="stats.TotalVAT > 0 ? '4' : '6'"
                            md="6"
                            cols="12"
                        >
                            <b-card no-body class="ren-default-card">
                                <b-card-body class="px-1 py-1">
                                    <h6 class="font-small-2">{{ i18nT(`Revenu brut`) }} <br> {{ i18nT(`(année)`) }}</h6>
                                    <h3
                                        class="m-0 text-primary"
                                    >
                                        {{ {amount: stats.TotalGrossIncome} | currency }}
                                    </h3>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            :lg="stats.TotalVAT > 0 ? '4' : '6'"
                            md="6"
                            cols="12"
                        >   
                            <b-card no-body class="ren-default-card">
                                <b-card-body class="px-1 py-1">
                                    <h6 class="font-small-2">{{ i18nT(`Dépenses`)}} <br> {{ i18nT(`(année)`) }}</h6>
                                    <h3 
                                        class="m-0 text-danger"
                                    >
                                        {{ {amount: stats.TotalExpenses} | currency }}
                                    </h3>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            lg="4"
                            md="6"
                            cols="12"
                            v-if="stats.TotalVAT > 0"
                        >
                            <b-card no-body class="ren-default-card">
                                <b-card-body class="px-1 py-1">
                                    <h6 class="font-small-2">{{ i18nT(`TVA encaissée`)}} <br> {{ i18nT(`(année)`) }}</h6>
                                    <h3
                                        class="m-0 text-primary"
                                    >
                                        {{ {amount: stats.TotalVAT} | currency }}
                                    </h3>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            :lg="currentUnit.PropertyInitialPrice > 0 ? '4' : '6'"
                            md="6"
                            cols="12"
                        >
                            <b-card no-body class="ren-default-card">
                                <b-card-body class="px-1 py-1">
                                    <div class="d-flex align-items-center mb-1">
                                        <h6 class="mr-50 font-small-2 m-0 pt-25 pb-25">{{ i18nT(`Taux d'occupation`) }} <br> {{ i18nT(`(année)`) }}</h6>
                                    </div>
                                    <h3
                                        class="m-0"
                                    >
                                        {{ +stats.OccupationRate }} %
                                    </h3>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            :lg="currentUnit.PropertyInitialPrice > 0 ? '4' : '6'"
                             md="6"
                             cols="12"
                        >
                            <b-card no-body class="ren-default-card">
                                <b-card-body class="px-1 py-1">
                                    <div class="d-flex align-items-center mb-1">
                                        <h6 class="mr-50 font-small-2 m-0 pt-25 pb-25">{{ i18nT(`Rentabilité`) }} <br> {{ i18nT(`(nette)`) }}</h6>
                                        <span v-b-tooltip.hover="i18nT(`La rentabilité locative nette est calculé à la base de la formule suivante: (Loyer annuel – Dépenses) x 100 / Prix d’acquisition du bien`)" >
                                            <RtIcon variant="light mb-1" icon="circle-info"/>
                                        </span>
                                    </div>
                                   <h3
                                        class="m-0"
                                        v-if="currentUnit.PropertyInitialPrice"
                                    >
                                        {{ stats.Profitability }} %
                                    </h3>
                                    <div class="mb-50 font-small-3" v-if="!currentUnit.PropertyInitialPrice">
                                        <b-link
                                            :to="{name: 'edit-property', params: {id: this.currentUnitId, tab: 'financial-info'}}"
                                            >
                                            {{ i18nT(`Prix d'acquisition inconnu`) }}
                                        </b-link>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            lg="4"
                            md="6"
                            cols="12"
                            v-if="currentUnit.PropertyInitialPrice"
                        >
                            <b-card no-body class="ren-default-card">
                                <b-card-body class="px-1 py-1">
                                    <div class="d-flex align-items-center mb-1">
                                        <h6 class="mr-50 font-small-2 m-0 pt-25 pb-25">{{ i18nT(`Rentabilité`) }} <br> {{ i18nT(`(brute)`) }}</h6>
                                        <span v-b-tooltip.hover="i18nT(`La rentabilité locative brute est calculé à la base de la formule suivante: (Loyer annuel) x 100 / Prix d’acquisition du bien`)">
                                            <RtIcon variant="light mb-1" icon="circle-info"/>
                                        </span>
                                    </div>
                                    <h3
                                        class="m-0"
                                    >
                                        {{ stats.ProfitabilityGross }} %
                                    </h3>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            cols="12"
                        >
                            <b-card no-body class="ren-default-card">
                                <b-card-body class="px-1 py-1 d-flex align-items-center">
                                    <div class="d-flex justify-content-center align-items-center mr-2">
                                        <b-avatar size="6em" variant="light-warning">
                                            <RtIcon variant="thin" icon="coins" :size="37"/>
                                        </b-avatar>
                                    </div>
                                    <div>
                                        <h6>{{ i18nT(`Solde locataire`) }}</h6>
                                        <h1 
                                            class="m-0"
                                            :class="stats.TotalNetIncome  >= 0 ? 'text-primary' : 'text-danger'"
                                        >
                                            {{ {amount: stats.TotalNetIncome } | currency }}
                                        </h1>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            cols="12"
                        >
                            <div class="mt-1 d-flex justify-content-around">
                                <b-button class="w-100 mr-1" variant="outline-primary">{{ i18nT(`Finances`)}}</b-button>
                                <b-button class="w-100" variant="outline-primary">{{ i18nT(`Détail du solde`)}}</b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>
        <!-- <b-tab>
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="key"/>
                    </div>
                    <p class="m-0">
                        {{ i18nT(`Locations`)}}
                    </p>
                </div>
            </template>
            <b-card
                no-body
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Locations`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col
                            cols="12"
                        >
                            <hr class="mt-0 mb-2"/>
                        </b-col>
                        <b-col
                            cols="12"
                            v-for="(rentals, index) in rentedProperties"
                            :key="'rentals' + index"
                        >
                            <b-card no-body border-variant="light">
                                <b-card-body>
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex flex-column justify-content-between ">
                                            <b-link>{{ rentals.PropertyTitle }}</b-link>
                                            <span>{{ rentals.PropertyAddress }} {{ rentals.PropertyPostalCode }} {{ rentals.PropertyCity }}</span>
                                            <p class="m-0">{{ rentals.LeaseType }}</p>
                                            <p class="m-0">{{ rentals.LeaseStartDate | date }} - {{ rentals.LeaseEndDate | date }}</p>
                                            <p class="m-0">{{ i18nT(`Loyer  `) }}
                                                <span class="text-primary">{{ {amount: rentals.LeaseMonthlyAmount} | currency }}</span>
                                            </p>
                                            <p class="m-0">{{ i18nT(`Charges  `) }}
                                                <span class="text-primary">{{ {amount: rentals.LeaseMaintenance} | currency }}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <span class="badge badge-primary" v-if="rentals.LeaseActive === '1'">{{ i18nT(`ACTIVE`)}}</span>
                                            <span class="badge badge-warning" v-else>{{ i18nT(`INACTIF`)}}</span>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col cols="12">
                            <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab> -->
        <b-tab>
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="screwdriver-wrench"/>
                    </div>
                    <p class="m-0">
                        {{ i18nT(`Interventions`)}}
                    </p>
                </div>
            </template>
            <b-card
                no-body
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Interventions`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col
                            cols="12"
                        >
                            <hr class="mt-0 mb-2"/>
                        </b-col>
                        <b-col
                            cols="12"
                        >
                            <div
                                v-for="(item,index) in maintenance"
                                :key="`maintenance` + index"
                                class="pt-1"
                            >
                                <maintenance-card :maintenance="item" />
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>
        <b-tab>
        <template #title>
            <div class="d-flex align-items-center ren-title">
                <div class="mr-50">
                    <RtIcon :size="18" variant="light" icon="file-contract"/>
                </div>
                <p class="m-0 text-nowrap">
                    {{ i18nT(`Contrats et diagnostics`)}}
                </p>
            </div>
        </template>
        <b-card
            no-body
        >
            <b-card-header class="pb-0">
                <h4>{{ i18nT(`Contrats et diagnostics`) }}</h4>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col
                        cols="12"
                    >
                        <hr class="mt-0 mb-2"/>
                    </b-col>
                    <b-col
                        cols="12"
                        v-for="(contract, index) in contracts"
                        :key="'contract' + index"
                    >
                        <contract-card :contract="contract" :contractTypes="contractTypes" />
<!--                        <b-card no-body border-variant="secondary">-->
<!--                            <b-card-body class="pt-75 pb-75">-->
<!--                                <b-row>-->
<!--                                    <b-col-->
<!--                                        cols="12"-->
<!--                                        md="12"-->
<!--                                        lg="1"-->
<!--                                        class="text-center mt-75 mb-1"-->
<!--                                        sm="12"-->
<!--                                    >-->
<!--                                        <rt-icon icon="file" variant="thin" :size="35" />-->
<!--                                    </b-col>-->
<!--                                    <b-col-->
<!--                                        md="12"-->
<!--                                        lg="10"-->
<!--                                        cols="12"-->
<!--                                        class="pl-2"-->
<!--                                        sm="12"-->
<!--                                    >   -->
<!--                                        <span-->
<!--                                            v-if="contract.PropertiesContractType === 1"-->
<!--                                        >-->
<!--                                            <p class="text-truncate m-0">-->
<!--                                                {{ i18nT(`Assurance propriétaire`) }}-->
<!--                                            </p>-->
<!--                                            <h5 class="cursor-pointer text-truncate w-75 5 mb-25 mt-0">{{ contract.PropertiesContractText }}</h5>-->
<!--                                            <div class="d-flex">-->
<!--                                                <rt-icon icon="paperclip" variant="thin" :size="16" />-->
<!--                                                <b-link :href="contract.download_url" v-b-tooltip.hover="i18nT(`Télécharger l'attestation`)">-->
<!--                                                    {{ contract.download_name }}-->
<!--                                                </b-link>-->
<!--                                            </div>-->
<!--                                        </span>-->
<!--                                        <span v-if="contract.PropertiesContractType === 3">-->
<!--                                            <p class="text-truncate m-0">-->
<!--                                                {{ i18nT(`Diagnostic performances énergétiques`) }}-->
<!--                                            </p>-->
<!--                                            <p class="text-truncate m-0">-->
<!--                                                {{ i18nT(`Consommations énergétiques`) }} {{ contract.PropertyEnergyConsumption }} {{ contract.PropertyEnergyConsumptionIndex }}-->
<!--                                            </p>-->
<!--                                            <p class="text-truncate m-0">-->
<!--                                                {{ i18nT(`Emissions de gaz à effet de serre`) }}-->
<!--                                            </p>-->
<!--                                        </span>-->
<!--                                        &lt;!&ndash; TODO ADD MIDDLE SECTION &ndash;&gt;-->
<!--                                        &lt;!&ndash; <p-->
<!--                                            class="text-truncate m-0"-->
<!--                                        >-->
<!--                                            {{ currentDocument.DocumentDescription? currentDocument.DocumentDescription : i18nT(`Pas de description`) }}-->
<!--                                        </p> &ndash;&gt;-->
<!--                                        <small>{{ contract.PropertiesContractDate | date }} - {{ contract.PropertiesContractDateTo | date }}</small>-->
<!--                                    </b-col>-->
<!--                                    <b-col-->
<!--                                        cols="12"-->
<!--                                        md="12"-->
<!--                                        sm="12"-->
<!--                                        lg="1"-->
<!--                                    >-->
<!--                                        <b-badge-->
<!--                                            v-if="!contract.Expired"-->
<!--                                            variant="warning"-->
<!--                                            class="pill float-right mb-1 ml-1"-->
<!--                                        >-->
<!--                                            <p class="m-0"> {{ i18nT(`Expiré`)}}</p>-->
<!--                                        </b-badge>-->
<!--                                    </b-col>-->
<!--                                </b-row>-->
<!--                            </b-card-body>-->
<!--                        </b-card>-->
                    </b-col>
                    <b-col cols="12">
                        <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </b-tab>
        <b-tab>
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="clipboard-check"/>
                    </div>
                    <div class="d-flex">
                        <span class="pt-25">{{ i18nT(`Tâches`)}}</span>
                        <b-badge class="ml-50" variant="primary">2</b-badge>
                    </div>
                </div>
            </template>
            <b-card
                no-body
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Tâches en cours`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col
                            cols="12"
                        >
                            <hr class="mt-0 mb-2"/>
                        </b-col>
                        <b-col
                            cols="12"
                            v-for="(task, index) in tasks"
                            :key="'task' + index"
                        >
                            <task-card :task="task" :isCompleted="taskCompleteBtnClicked" :selected-task="selectedTask" @complete="completeTask"/>

<!--                            <b-card no-body border-variant="primary">-->
<!--                                <b-card-body>-->
<!--                                    <div class="d-flex justify-content-between">-->
<!--                                        <div class="d-flex justify-content-between align-items-center">-->
<!--                                            <span-->
<!--                                                :class="{'text-primary': taskCompleteBtnClicked && selectedTask.TaskID === task.TaskID}"-->
<!--                                                @click="completeTask(task)"-->
<!--                                            >-->
<!--                                                <RtIcon-->
<!--                                                    :size="25"-->
<!--                                                    variant="solid"-->
<!--                                                    icon="check-circle mr-1 cursor-pointer ren-task-box"-->
<!--                                                />-->
<!--                                            </span>-->
<!--                                            <span class="mr-1 text-primary">{{task.TaskSubject}}</span>-->
<!--                                            <span>{{task.TaskDeadline | date}}</span>-->
<!--                                        </div>-->
<!--                                        <div>-->
<!--                                            <span v-if="task.TaskDeliveryType !== 'once'" class="badge badge-success">-->
<!--                                                {{ i18nT('RÉPÉTER')}}-->
<!--                                            </span>-->
<!--                                            <span v-else-if="task.TaskActive === 1 && compareDate(task.TaskDeadline)" class="badge badge-primary">-->
<!--                                                {{ i18nT(`ACTIVE`)}}-->
<!--                                            </span>-->
<!--                                            <span v-else-if="task.TaskActive === 1 && !compareDate(task.TaskDeadline)" class="badge badge-warning">-->
<!--                                                {{ i18nT(`EN RETARD`)}}-->
<!--                                            </span>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </b-card-body>-->
<!--                            </b-card>-->
                        </b-col>
                        <b-col cols="12">
                            <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>
        <b-tab
            :title="i18nT(`Notes`)"
        >
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="sticky-note"/>
                    </div>
                    <p class="m-0">
                        {{ i18nT(`Notes`)}}
                    </p>
                </div>
            </template>
            <b-card
                no-body
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Notes`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col
                            cols="12"
                        >
                            <hr class="mt-0 mb-2"/>
                        </b-col>
                        <b-col
                            cols="12"
                            v-for="(note, index) in notes"
                            :key="'note' + index"
                        >
                            <note-card :note="note" @delete="deleteNote(index)" />
<!--                            <b-card no-body border-variant="primary">-->
<!--                                <b-card-body :class="`note-bg-${note.NoteColour}`">-->
<!--                                    <b-row>-->
<!--                                        <b-col-->
<!--                                            cols="12"-->
<!--                                            md="1"-->
<!--                                            class="text-center"-->
<!--                                        >-->
<!--                                        <rt-icon icon="note-sticky" variant="thin" :size="40" />-->
<!--                                        </b-col>-->
<!--                                        <b-col-->
<!--                                            md="10"-->
<!--                                            cols="12"-->
<!--                                            class="pl-2"-->
<!--                                        >-->
<!--                                            <h5 class="cursor-pointer text-truncate w-75 mb-0">{{ note.NoteText }}</h5>-->
<!--                                            <small>{{ note.NoteCreateTime | date }}</small>-->
<!--                                        </b-col>-->
<!--                                        <b-col-->
<!--                                            cols="12"-->
<!--                                            md="1"-->
<!--                                            sm="12"-->
<!--                                        >-->
<!--                                            <b-button-->
<!--                                                variant="warning"-->
<!--                                                class="pill float-right ml-1"-->
<!--                                                @click="deleteNote(index)"-->
<!--                                            >-->
<!--                                                <rt-icon icon="trash text-danger" variant="regular"/>-->
<!--                                            </b-button>-->
<!--                                        </b-col>-->
<!--                                    </b-row>-->
<!--                                </b-card-body>-->
<!--                            </b-card>-->
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                                :label="i18nT(`Nouvelle note`)"
                            >
                                <b-form-textarea
                                    :id='i18nT(`Nouvelle note`)'
                                    v-model="newNote"   
                                    :rows="4"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-button 
                                class="float-right mb-1" 
                                :disabled="newNote.length <= 0" 
                                variant="outline-primary"
                                @click="saveNewNote"
                            >
                                {{ i18nT(`Sauvegarder`) }}
                            </b-button>
                        </b-col>
                        <b-col cols="12">
                            <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>
        <b-tab
            :title="i18nT(`Documents`)"
            v-if="documentsCount > 0"
        >
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="briefcase"/>
                    </div>
                    <p class="m-0">
                        {{ i18nT(`Documents`)}}
                        <b-badge variant="primary">{{documentsCount}}</b-badge>
                    </p>
                </div>
            </template>
            <document-tab @countChanged="updateDocumentCount"/>
            <b-card
                no-body
                v-if="false"
            >

                <b-card-body>
                    <b-row>
                        <b-col
                            cols="6"
                        >
                            <h4>{{ i18nT(`Documents`) }}</h4>
                        </b-col>
                        <b-col
                            cols="6"
                            class="pr-1 d-flex justify-content-end"
                        >
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div class="input-group input-group-merge" role="group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <RtIcon variant="light" icon="search"/>
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model.lazy="searchQuery"
                                            :placeholder="i18nT(`Chercher`)"
                                            class="d-inline-block"
                                            type="text"
                                            @input="getSearchResults(searchQuery)"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </b-col>
                        <b-col
                            cols="12"
                            class="mb-1"
                        >
                            <b-table
                                    :key="'DocumnetsTable' + documentTableColumns.length"
                                    :items="searchResultArray"
                                    :fields="documentTableColumns"
                                    responsive
                                    class="position-relative"
                            >
                                <template #cell(DocumentName)="data">
                                    <div class="d-flex align-items-center">
                                        <div class="cursor-pointer" @click="sortByType(data.item.DocumentType)">
                                            <b-avatar size="5em" :variant="getIconVariant(data.item)">
                                                <rt-icon :icon="getIcon(data.item)" variant="thin" :size="34"/>
                                            </b-avatar>
<!--                                            <rt-icon :icon="getIcon(data.item)" variant="thin" :size="38"/>-->
                                        </div>
                                        <div class="d-flex flex-column">
                                            <h6 class="m-0 ml-1 ">
                                                <b-link :href="data.item['download_url']">{{ data.item.DocumentName }}</b-link>
                                            </h6>
                                            <small class="m-0 ml-1 mt-25">{{ data.item.DocumentCreateTime | date }}</small>
                                            <p class="m-0 ml-1 mt-25">{{ data.item.DocumentDescription }}</p>
                                        </div>
                                    </div>

                                </template>
                                <template #cell(status)="data">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <b-badge
                                            v-b-tooltip="getTooltipLabel(data.item.DocumentIsShared, data.item['_can_edit'])"
                                            :variant="statusLabelClass(data.item.DocumentIsShared, data.item['_can_edit'])"
                                            pill
                                        >
                                                {{
                                                    statusLabel(data.item.DocumentIsShared, data.item['_can_edit'], data.item)
                                                }}
                                        </b-badge>
                                    </div>
                                </template>
                                <template #cell(actions)="data">
                                    <b-dropdown
                                        no-caret
                                        toggle-class="p-0"
                                        variant="link"
                                        dropleft
                                    >
                                        <template #button-content>
                                            <RtIcon variant="light" icon="ellipsis-vertical align-middle text-body ml-1" :size="18"/>
                                        </template>
                                        <b-dropdown-item
                                            :to="{name:'edit-property', params:{id: data.item.PropertyID}}"
                                        >
                                            <RtIcon variant="light" icon="edit" />
                                            <span class="align-middle ml-50">{{ i18nT(`Modifier`) }}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item
                                            @click="onArchive(data.item)"
                                        >
                                            <RtIcon variant="light" icon="box-archive"/>
                                            <span class="align-middle ml-50">
                                            {{ i18nT(`Archiver`)}}
                                </span>
                                        </b-dropdown-item>
                                        <b-dropdown-item
                                            @click="onDelete(data.item)"
                                        >
                                            <RtIcon variant="light" icon="trash text-danger"/>
                                            <span class="align-middle ml-50 text-danger">
                                            {{i18nT(`Supprimer`)}}
                                </span>
                                        </b-dropdown-item>
                                    </b-dropdown>
<!--                                    <div class="d-flex justify-content-between px-25">-->
<!--                                        <b-link-->
<!--                                            :disabled="data.item['_can_edit']==0"-->
<!--                                            :class="data.item['_can_edit'] == 1? 'text-info' : 'text-secondary' "-->
<!--                                            v-b-tooltip.hover.top="i18nT(`Modifier`)"-->
<!--                                        >-->
<!--                                            <RtIcon variant="light" icon="edit mr-1" :size="15"/>-->
<!--                                        </b-link>-->
<!--                                        <b-link-->
<!--                                            :disabled="data.item['_can_edit'] == 0"-->
<!--                                            :class="data.item['_can_edit'] == 1 ? 'text-danger' : 'text-secondary' "-->
<!--                                            v-b-tooltip.hover.top="i18nT(`Supprimer`)"-->
<!--                                        >-->
<!--                                            <RtIcon variant="light" icon="trash" :size="15"/>-->
<!--                                        </b-link>-->
<!--                                    </div>-->
                                </template>
                            </b-table>
                            <document-tab />
<!--                            <div-->
<!--                                v-for="(item,index) in documents"-->
<!--                                :key="`document` + index"-->
<!--                                class="pb-1 pt-1"-->
<!--                            >-->
<!--                                <document-card :document="item" />-->
<!--                            </div>-->
                        </b-col>
                        <b-col cols="12">
                            <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>
        <b-tab
            :title="i18nT(`Activité`)"
        >
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="clock-rotate-left"/>
                    </div>
                    <p class="m-0">
                        {{ i18nT(`Activité`)}}
                    </p>
                </div>
            </template>
            <b-card
                no-body
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Historique`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col
                            cols="12"
                        >
                            <hr class="mt-0 mb-2"/>
                            <app-timeline>
                                <app-timeline-item
                                    v-for="(log, index) in logs"
                                    :key="index + 'log'"
                                    :title="log.ActionText"
                                    :subtitle="'Email: ' + log.LogUserEmail"
                                    :icon="getTimelineIcon(log.LogAction)"
                                    :variant="getTimelineVariant(log.LogAction)"
                                    :time="formatDate(log.CreatedAt)"
                                    :showDelete="false"
                                />
                            </app-timeline>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>
        <div class="ps__rail-y">
            <div class="ps__thumb-y"></div>
        </div>
    </b-tabs>
</template>




<script>
import {
    BAvatar,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BCardHeader,
    BCardBody,
    BFormTextarea,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BForm,
    BFormRating,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    BModal,
    BProgress,
    VBTooltip,
} from 'bootstrap-vue'
import router from '@/router'
import { dictToSelectArray } from '@core/utils/utils'
import VueApexCharts from 'vue-apexcharts'
import ContactCard from "@/views/components/contactCard/ContactCard.vue";
import ContractCard from "@/views/common/Contracts/ContractCard.vue";
import MaintenanceCard from '@/views/components/maintenanceCard/MaintenanceCard.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DocumentCard from "@/views/common/Documents/DocumentCard.vue";
import useAuth from '@/auth/useAuth'
import moment from 'moment';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@/views/components/activityTimelineItem/ActivityTimelineItem.vue'
import TaskCard from "@/views/components/taskCard/TaskCard.vue";
import NoteCard from "@/views/components/noteCard/NoteCard.vue";
import vSelect from 'vue-select'
import store from '@/store'
import DocumentTab from '@/views/components/tabs/DocumentTab/DocumentTab.vue'


export default{
    components:{
        NoteCard,
        TaskCard,
        DocumentCard,
        ContactCard,
        ContractCard,
        BAvatar,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BCardHeader,
        BCardBody,
        BFormTextarea,
        BTabs,
        BTab,
        BDropdown,
        BDropdownItem,
        BBadge,
        BLink,
        BForm,
        BFormRating,
        BFormInput,
        BFormCheckbox,
        BModal,
        BProgress,
        MaintenanceCard,
        AppTimeline,
        AppTimelineItem,
        VueApexCharts,
        vSelect,
        DocumentTab,
    },
    directives:{
        'b-tooltip': VBTooltip
    },
    data(){
        return {
            stats: {},
            years: [],
            year: new Date().getFullYear(),
            maintenance: [],
            contracts: [],
            tasks: [],
            taskCompleteBtnClicked: false,
            notes: [],
            newNote: '',
            documentsCount: 0,
            logs: [],
            contractTypes: [],
            currentUnitId: router.currentRoute.params.id,
            currentUnit: {},
            selectedTask: {},
            pieChartOptions: 
            {
                fill: {
                    type: 'gradient',
                    gradient: {
                        opacityFrom: 0.9,
                        opacityTo: 1,
                        stops: [75, 95],
                    }
                },
            },
            pieSeries: [],
            areaChartOptions: {
                colors: ['#ea5455', '#72a333'],
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    type: 'gradient'
                },
            },
            areaSeries: [],
            darkLayout: store.state.appConfig.layout.skin,
            tableDocuments: [],
            documentTableColumns : [
                {key: 'DocumentName', label: this.i18nT(`Titre`), sortable: true, tdClass: 'pl-1'},
                // {key: 'description', label: this.i18nT(`Description`), sortable: true},
                // {key: 'DocumentCreateTime', label: this.i18nT(`Date`), sortable: true},
                {key: 'status', label: this.i18nT(`Etat`), thClass:'text-center'},
                {	key: 'actions',
                    label: this.i18nT(`Actions`),
                    thClass: 'tbl-actions',
                    tdClass: 'sticky-column',
                    stickyColumn: true,},
            ],
            formatDic: [
                {type: 'pdf', icon: 'file-pdf', background: 'light-danger'},
                {type: 'docx', icon: 'file-doc', background: 'light-info'},
                {type: 'doc', icon: 'file-doc', background: 'light-info'},
                {type: 'word', icon: 'file-word', background: 'light-info'},
                {type: 'openoffice', icon: 'file-lines', background: 'light-info'},
                {type: 'jpg', icon: 'file-jpg', background: 'light-warning'},
                {type: 'png', icon: 'file-png', background: 'light-secondary'},
                {type: 'xls', icon: 'file-xls', background: 'light-success'},
                {type: 'xlsx', icon: 'file-xls', background: 'light-success'},
            ],
            searchQuery: '',
            timeoutId: null,
            searchResultArray: [],

        }
    },
    props: {
        unitData: {
            type: Object,
            required: true
        }
    },
    created() {
        console.log('router: ', this.$router.currentRoute)

        console.log(this.darkLayout)
        this.getStats(true)
        
    },
    methods: {
        sortByType(type){
            console.log(type)
           this.searchResultArray = this.searchResultArray.sort((a)=> {
                return a.DocumentType === type ? -1 : 1
           })
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        },
        updateDocumentCount(newCount) {
            this.documentsCount = newCount
        },
        // getSearchResults(searchQuery){

        //     if(searchQuery === ''){
        //         this.searchResultArray = [...this.documents]
        //     }

        //     clearTimeout(this.timeoutId)
        //     this.timeoutId = setTimeout(() => {
        //         this.searchResultArray =  this.documents
        //             .filter(document => document.DocumentName.toLowerCase().includes(searchQuery) || document.DocumentDescription.toLowerCase().includes(searchQuery))
        //     }, 500)
        // },
        statusLabelClass(isShared, canEdit) {
            if (isShared == 1) {
                if (canEdit == 1) {
                    return "light-success";
                }
                return "light-info";
            } else {
                return "light-secondary";
            }
        },
        statusLabel(isShared, canEdit) {
            if (isShared == 1) {
                if (canEdit == 1) {
                    return this.i18nT("Partagé");
                }
                return this.i18nT("Partagé");
            } else {
                return this.i18nT("Privé");
            }
        },
        getTooltipLabel(isShared, canEdit) {
            if (isShared == 1) {
                if (canEdit == 1) {
                    return this.i18nT(`Document partagé avec le(s) locataire(s)`);
                }
                return this.i18nT(`Document partagé par le(s) locataire(s)`);
            } else {
                return this.i18nT(`Le document n'est pas partagé`);
            }
        },
        getIcon(document){
            const findIcon = this.formatDic.find((format) => format.type === document.DocumentType);
            if(findIcon){
                return findIcon.icon;
            } else {
                return 'file-text';
            }
        },
        getIconVariant(document){
            const findIcon = this.formatDic.find((format) => format.type === document.DocumentType);
            if(findIcon){
                return findIcon.background;
            } else {
                return 'light-secondary';
            }
        },
        getStats(showLoader = false) {
            this.$http.get(`landlord/properties/stats?property_id=${this.currentUnitId}&year=${this.year}`, {showLoader: showLoader === true})
            .then(({data}) => {
                console.log('Stats: ', data.data)
                this.stats = data.data.summary;


                this.areaChartOptions = {
                    xaxis: {
                        categories: data.data.income_expense.month_labels
                    },
                    theme: {
                        mode: this.isDarkMode ? 'dark' : 'light'
                    }
                }
                this.areaSeries = [
                    {
                        name: this.i18nT(`Dépense`),
                        data: data.data.income_expense.expenses_data
                        // data: data.data.expenses_data
                    },
                    {
                        name: this.i18nT(`Revenu`),
                        data: data.data.income_expense.incomes_data
                        // data: data.data.incomes_data
                    }
                ]
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(err),
                        icon: 'InfoIcon',
                        variant: 'danger'
                    }
                })
            })
        },
        completeTask(task) {

            // TODO Handle complete task when api is ready!
            this.taskCompleteBtnClicked = !this.taskCompleteBtnClicked

            this.selectedTask = task

            setTimeout(() => {
                if (!this.taskCompleteBtnClicked) return
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Status changed`,
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                    this.taskCompleteBtnClicked = false
            }, 2000)
        },
        compareDate(date) {

            const currentDate = moment().startOf('day')
            const inputDate = moment(date)

            if(inputDate.isBefore(currentDate)) {
                return false
            } 
            else if (inputDate.isAfter(currentDate)) {
                return true
            }
            else {
                return true
            }
        },
        getTimelineIcon(action) {
            if (action === 'insert') {
			    return 'plus'
            }
            else if (action === 'update') {
			    return 'arrow-up-from-bracket'
            }
            else if (action === 'execArchive') {
			    return 'box-archive'
            }
            else if (action === 'execUnarchive') {
			    return 'box-archive'
            }
            else if (action === 'deleteRestore') {
                return 'arrows-rotate'
            }
            else if (action === 'deleteTrash') {
			    return 'trash'
            }
        },    
        getTimelineVariant(action) {
            if(action === 'insert' || action === 'update') {
                return 'primary'
            } else if (action === 'execUnarchive' || action === 'deleteRestore') {
                return 'info'
            }
            else if (action === 'execArchive') {
                return 'warning'
            }
            else {
                return 'danger'
            }
        },
        formatDate(date) {
            let dateFormat = useAuth.getDateFormat() !== (null || 'null') ? useAuth.getDateFormat() : 'DD/MM/YYYY';
            dateFormat += ' HH:mm:ss'
            const formatted = moment(new Date(date)).format(dateFormat);
            
            return formatted;
        },
        deleteNote(index) {
            // TODO add call request when api is ready or push to parent comp

            this.notes.splice(index, 1)
        },
        saveNewNote() {
            // TODO add call request when api is ready or push to parent comp

            this.notes.push({
                NoteText: this.newNote,
                NoteCreateTime: new Date(),
                NoteColour: 'yellow',
            })
            this.newNote = ''
        },
    },
    watch: {
        unitData: {
            handler: function(val) {
                if(val) {
                    this.years =  val.years ? val.years.map(year => ({value: year, text: `${this.i18nT(`Année`)} ${year}`})) : [{value: 2024, text: `${this.i18nT(`Année`)} 2024`}]
                    this.currentUnit = val.property
                    this.maintenance = val.maintenance
                    this.contracts = val.contracts
                    this.tasks = val.tasks
                    this.notes = val.notes
                    this.documentsCount = val.documents
                    this.searchResultArray = val.documents
                    // this.tableDocuments = this.documents.map(document=> {
                    //     return {
                    //         title: document.DocumentName,
                    //         description: document.DocumentDescription,
                    //         create_at: document.DocumentCreateTime,
                    //     }
                    // })
                    this.logs = val.logs
                    this.contractTypes = val['contract_types'].map(type => ({value: type.id, text: type.label}))
                }
            },
            deep: true
        },
        isDarkMode(val) {
            this.$refs.areaChart.updateOptions({
                theme: {
                    mode: val ? 'dark' : 'light'
                }
            })
        }
    },
    computed: {
        isDarkMode(){
            return this.$store.state.appConfig.layout.skin === 'dark'
        }
    }
}
</script>


<style lang="scss">

.rt-tabs-list{
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 8px;
}

.rt-tabs-list .nav-link.active {
    box-shadow: none;
}

.rt-tabs-list::-webkit-scrollbar {
    width: 10px;
    height: 7px;
}

.rt-tabs-list:hover::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 20px;
}

// .rt-tabs-list:hover::-webkit-scrollbar-track {
//     background: #eee;
//     border-radius: 20px;
// }

.dark-layout .rt-tabs-list:hover::-webkit-scrollbar-thumb {
    background: #b4b7bd;
}

.dark-layout .rt-tabs-list:hover::-webkit-scrollbar-track {
    background: #6e6b7b;
}

a.disabled {
    pointer-events: none;
}




</style>